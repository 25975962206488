<template>
    <div>
        <!-- Bouton d'installation pour les navigateurs compatibles -->
        <button v-if="showInstallButton && !isChromeOnIOS" @click="installPWA">
            Installer l'application
        </button>

        <!-- Message pour Chrome sur iOS -->
        <div v-if="isChromeOnIOS" class="ios-message">
            <p>To install this app, use the "Share" option then "Add to Home Screen".</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InstallPWA',
    data() {
        return {
            showInstallButton: false,
            deferredPrompt: null,
            isChromeOnIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && navigator.userAgent.includes('CriOS'),
        };
    },
    mounted() {
        if (!this.isChromeOnIOS) {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                this.deferredPrompt = e;
                this.showInstallButton = true;
            });
        }
    },
    methods: {
        async installPWA() {
            if (this.deferredPrompt) {
                // Déclenche l'invite d'installation
                this.deferredPrompt.prompt();
                // Attend que l'utilisateur réponde à l'invite
                await this.deferredPrompt.userChoice;
                // Masque le bouton d'installation après que l'utilisateur ait répondu
                this.showInstallButton = false;
                // Réinitialise la variable deferredPrompt
                this.deferredPrompt = null;
            }
        },
    },
};
</script>

<style scoped>
button {
    background-color: #1d82b9;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    border-radius: 25px;
}
.ios-message {
    text-transform: none;
    background-color: #1d82b9;
    padding: 10px;
    border-radius: 25px;
    text-align: center;
}
</style>