<template>
    <div class="loved-view">
        <div class="searchCard">
            <searchCard />
        </div>
        <navigation :page="'loved'"></navigation>
        <div class="loved">
            <div class="lovedList">
                <ul>
                    <li v-for="(loved, index) in loveds" :key="loved.id">
                        <a :href="'/' + loved.id" class="card" :class="{ spotifyVerified: loved.spotifyVerified }">
                            <div class="img"><img :src="loved.cover" alt=""></div>
                            <div class="textBox">
                                <div class="textContent">
                                    <p class="h1">{{ loved.title }}</p>
                                    <p class="p">{{ loved.artist }}</p>
                                </div>
                                <button>
                                    <span class="material-symbols-rounded">search</span>
                                </button>
                            </div>
                        </a>
                        <Observer v-if="index == loveds.length - 1" @intersect="intersected" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="lovedEmpty" v-if="loading">
            <Loader />
        </div>
    </div>
</template>

<script>
import SearchCard from "@/components/SearchCard.vue";
import Navigation from "@/components/Navigation.vue";
import DooverAPI from "../common/api";
import Loader from '@/components/Loader.vue';
import Observer from "@/components/Observer.vue";

export default {
    name: "Loved-view",
    components: {
        SearchCard,
        Navigation,
        Loader,
        Observer,
    },
    data() {
        return {
            search: '',
            loveds: [],
            loading: false,
        };
    },
    mounted() {
        this.getLoved();
    },
    methods: {
        async getLoved() {
            this.loading = true;
            const dooverAPI = new DooverAPI();
            this.loveds = await dooverAPI.getLovedTracks().then((loveds) => {
                this.loading = false;
                return loveds;
            });
        },
        async intersected() {
            const dooverAPI = new DooverAPI();
            if (this.loveds.length > 0) {
                const newLoveds = await dooverAPI.getLovedTracks(this.loveds.length);
                this.loveds = this.loveds.concat(newLoveds);
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.loved-view {
    background-color: black;
    height: 100vh;

    @media screen and (min-width: 700px) {
        width: 60%;
        max-width: 600px;
        margin: auto;
        .searchCard {
            display: none;
        }
    }

    .loved {
        position: relative;

        .lovedList {
            position: absolute;
            justify-content: center;
            height: calc(100vh - 70px - 7vh);
            top: 7vh;
            color: white;
            width: 100%;
            padding-top: 25px;
            overflow-y: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar { 
                display: none;  /* Safari and Chrome */
            }
            @media screen and (min-width: 700px) {
                top: 0;
                height: 100vh;
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                li {
                    width: 90%;
                    margin-bottom: 5px;
                }
            }

            .card {
                width: 100%;
                height: 70px;
                text-decoration: none;
                background: #353535;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: left;
                &.spotifyVerified {
                    border: solid 2px #2ea31a;
                }
            }

            .img {
                height: 90%;
                aspect-ratio: 1;
                margin-left: 10px;
                border-radius: 10px;
                background: linear-gradient(#d7cfcf, #9198e5);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            .textBox {
                width: calc(100% - 90px);
                margin-left: 10px;
                color: white;
                display: flex;
                justify-content: space-between;
                
                button {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    text-decoration: none;
                    background: none;
                    border: none;
                    align-items: center;
                    margin-right: 10px;
                    .material-symbols-rounded {
                        font-size: 20px;
                        color: white;
                    }
                }

                .textContent {
                    width: 90%;
                    overflow: hidden;
                    .h1 {
                        font-size: 16px;
                        font-weight: bold;
                        white-space: nowrap;
                    }
                    .p {
                        font-size: 12px;
                        font-weight: lighter;
                        white-space: nowrap;
                    }
                }
            }

        }
    }
}
</style>
