<template>
  <div>
    <p v-if="error">Erreur : {{ error }}</p>
    <p v-else-if="accessToken">
      Connecté avec succès !
      <br>Access Token : {{ accessToken }}
      <br>Refresh Token : {{ refreshToken }}
    </p>
  </div>
</template>

<script>
import DooverAPI from "../../src/common/api";
import { useRouter } from 'vue-router'; // Pour gérer la redirection

export default {
  setup() {
    const router = useRouter(); // Initialisation du router
    return {
      router,
    };
  },
  data() {
    return {
      accessToken: null,
      refreshToken: null,
      error: null,
    };
  },
  async mounted() {
    const dooverAPI = new DooverAPI;
    const code = new URLSearchParams(window.location.search).get('code');
    if (!code) {
      this.error = 'Code de connexion manquant.';
      return;
    }

    try {
      const tokenData = await dooverAPI.fetchAccessToken(code);
      this.accessToken = tokenData.accessToken;
      this.refreshToken = tokenData.refreshToken; // Récupère le refresh token

      // dooverAPI.setCookie('token', tokenData.accessToken, tokenData.expiresIn*1000);

      // dooverAPI.setCookie('refreshToken', tokenData.refreshToken, 999999999);

      // Redirige vers la page d'accueil
      this.router.push({ name: 'discover' });
      // Planifie le rafraîchissement du token
      // this.scheduleTokenRefresh(tokenData.expiresIn);
    } catch (err) {
      this.error = 'Échec de la connexion.';
    }
  },
  methods: {
    scheduleTokenRefresh(expiresIn) {
      const dooverAPI = new DooverAPI;
      setTimeout(async () => {
        try {
          const newTokenData = await dooverAPI.refreshAccessToken(this.refreshToken);
          this.accessToken = newTokenData.accessToken;

          // Planifie un autre renouvellement après expiration
          this.scheduleTokenRefresh(newTokenData.expiresIn);
        } catch (err) {
          console.error('Erreur lors du renouvellement du token', err);
        }
      }, expiresIn * 1000); // Convertit en millisecondes
    },
  },
};
</script>
