<template>
    <div class="discover">
        <div class="searchCard">
            <searchCard/>
        </div>
        <navigation :page="'discover'"></navigation>
        <discoverCard></discoverCard>
    </div>
</template>

<script>
import SearchCard from "@/components/SearchCard.vue";
import DiscoverCard from "@/components/DiscoverCard.vue";
import Navigation from "@/components/Navigation.vue";
// import DooverAPI from "../common/api";

export default {
    name: "Discover-view",
    components: {
        SearchCard,
        DiscoverCard,
        Navigation,
    },
    data() {
        return {
            search: '',
        };
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.discover {
    background-color: black;
    width: 100%;
    position: relative;
    @media screen and (min-width: 700px) {
        width: 60%;
        max-width: 600px;
        margin: auto;
        .searchCard {
            display: none;
        }
    }
}
</style>
