import axios from 'axios';
import ColorThief from 'colorthief';

const CLIENT_ID = '55971b5193b147a8a438659c18ae9fff';
const REDIRECT_URI = location.origin + '/callback';
const AUTH_URL = 'https://accounts.spotify.com/authorize';
const TOKEN_URL = 'https://accounts.spotify.com/api/token';

const URL_BASE_API = 'https://api.doover.studio';
// const URL_BASE_API = 'http://localhost:3000';

class dooverAPI {

    setCookie(cname, cvalue, ctime) {
        const d = new Date();
        ctime = ctime ?? (1 * 3600 * 1000);
        d.setTime(d.getTime() + ctime);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    supprCookie(cname) {
        const d = new Date();
        d.setTime(d.getTime() - (1 * 3600 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "='';" + expires + ";path=/";
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    logout() {
        this.supprCookie('token');
        location.href = 'https://www.spotify.com/logout/';
    }

    getSpotifyAuthURL() {
        // console.log('getSpotifyAuthURL:', REDIRECT_URI);
        const scopes = 'playlist-modify-public playlist-modify-private playlist-read-private playlist-read-collaborative user-modify-playback-state user-read-recently-played user-library-read user-top-read user-library-modify';
        return `${AUTH_URL}?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=${encodeURIComponent(scopes)}`;
    }

    async fetchAccessToken(code) {
        const data = new URLSearchParams({
            grant_type: 'authorization_code',
            code,
            redirect_uri: REDIRECT_URI,
            client_id: CLIENT_ID,
            client_secret: '4742886de3b74ce5979f04dfdf3ec4c2',
        });
    
        const response = await axios.post(TOKEN_URL, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
    
        this.setCookie('token', response.data.access_token, response.data.expires_in * 1000);
        this.setCookie('refreshToken', response.data.refresh_token, 999999999)
        return {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token, // Spotify renvoie ce champ
            expiresIn: response.data.expires_in, // Optionnel : durée de validité
        };
    }

    async refreshAccessToken(refreshToken) {
        const data = new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: CLIENT_ID,
            client_secret: '4742886de3b74ce5979f04dfdf3ec4c2',
        });
        
        const response = await axios.post(TOKEN_URL, data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        this.setCookie('token', response.data.access_token, response.data.expires_in * 1000);
        return {
            accessToken: response.data.access_token,
            expiresIn: response.data.expires_in, // Optionnel
        };
    }

    async search(search, limit, offset) {
        const token = this.getCookie('token'); // Assure-toi que getCookie est correctement implémentée
        let temp;
        let results = [];
        try {
            const response = await axios.get('https://api.spotify.com/v1/search', {
                headers: {
                    Authorization: 'Bearer ' + token, // Utilisation correcte du token
                },
                params: {
                    q: search, // Utilisation du paramètre de recherche
                    type: 'track',
                    limit: limit,
                    offset: offset
                }
            });
    
            // Récupération des résultats
            temp = response.data.tracks.items;
            for (let index = 0; index < temp.length; index++) {
                const element = temp[index];
                results.push({
                    id: element.id,
                    title: element.name,
                    artist: element.artists[0].name,
                    cover: element.album.images[2].url,
                });
            }
        } catch (error) {
            console.error('Erreur lors de la requête Spotify :', error);
            results = null; // Gérer le cas d'erreur, peut-être retourner un tableau vide ou un message
        }
    
        return results;
    }

    async getSimilarTracks(recordID) {
        const token = this.getCookie('token');
        const refresh_token = this.getCookie('refreshToken');
        if(!token && refresh_token) {
            // console.log('refresh_token', refresh_token);
            await this.refreshAccessToken(refresh_token)
            return this.getSimilarTracks(recordID);
        }
        try {
            const { data: { results = [] } = {} } = await axios.get(`${URL_BASE_API}/similar?id=${recordID}&token=${token}`);
            return results;
        } catch (error) {
            console.error(`Erreur lors de la requête vers MusicBrainz avec "${recordID}":`, error.message);
            return [];
        }
    }

    async getSpotifyID(recordID) {
        let results = [];
        let spotifyID = '';
        try {
            const response = await axios.get(`https://labs.api.listenbrainz.org/spotify-id-from-mbid/json?recording_mbid=${recordID}`);
            results = response.data;
            if (results.length > 0) {
                for (let index = 0; index < results.length; index++) {
                    const element = results[index];
                    if (element.spotify_track_ids.length > 0) {
                        spotifyID = element.spotify_track_ids[0];
                    } else {
                        spotifyID = 'none';
                        // faire un search a sur spotify API pour trouver le track
                    }
                }
            }   
        } catch (error) {
            console.error('Erreur lors de la requête musicbrainz :', error);
        }
        return spotifyID;
    }

    async searchMB(search) {
        try {
            const { data: { results = [] } = {} } = await axios.get(`${URL_BASE_API}/search`, { params: { search } });
            return results;
        } catch (error) {
            console.error(`Erreur lors de la requête vers MusicBrainz avec "${search}":`, error.message);
            return [];
        }
    }

    async getUserRecentPlayed() {
        const token = this.getCookie('token');
        if (token) {
            try {
                const { data: { results = [] } = {} } = await axios.get(`${URL_BASE_API}/recentlyPlayed?token=${token}`);
                return results;
            } catch (error) {
                console.error(`Erreur lors de la requête vers spotify`, error.message);
                return [];
            }
        }
    }

    async toggleLike(track) {
        const token = this.getCookie('token');
        const refresh_token = this.getCookie('refreshToken');
        if(!token && refresh_token) {
            await this.refreshAccessToken(refresh_token)
            return this.toggleLike(track);
        }
        try {
            if (!track.liked) {
                await axios.get(`${URL_BASE_API}/like`, { params: { id: track.spotify.id, token }});
                return true;
            } else {
                await axios.get(`${URL_BASE_API}/unlike`, { params: { id: track.spotify.id, token }});
                return false;
            }
        } catch (error) {
            console.error('Erreur lors de la requête pour aimer un morceau :', error.message);
            return false;
        }
    }

    async getPlaylistTracks() {
        const token = this.getCookie('token'); // Assure-toi que getCookie est bien implémentée
        let topTracks = [];
        let results = [];
        
        if (token) {
            try {
                // Requête pour obtenir les pistes
                const response = await axios.get('https://api.spotify.com/v1/playlists/06mEDC7quGvS12TfPedkNI/tracks', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });

                topTracks = response.data.items
    
            } catch (error) {
                // Gestion des erreurs
                console.error('Erreur lors de la récupération des pistes :', error.response ? error.response.data : error.message);
            }
        } else {
            console.error("Le token est manquant ou invalide.");
        }

        for (let index = 0; index < topTracks.length; index++) {
            const element = topTracks[index].track;

            let artists = [];
            for (let index = 0; index < element.artists.length; index++) {
                const artist = element.artists[index];
                artists.push(artist.name);
            }

            results.push({
                id: element.id,
                title: element.name,
                artist: artists.join(' - '),
                cover: element.album.images[1].url,
                uri: element.uri,
                album: element.album.name,
            });
        }

        let temp = [];
        for (let index = 0; index < results.length; index++) {
            const element = results[index];
            temp.push(await this.getYoutubeVideo(element.title, element.artist));
        }
        let tata = [
            {
                "kind": "youtube#searchResult",
                "etag": "CelZIY9mqmui3_D5t8lIGFaYLX8",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "apJTVSnpeBw"
                },
                "snippet": {
                    "publishedAt": "2024-05-10T13:55:54Z",
                    "channelId": "UCwUvtUrfhkS4CTeF2HHTtgg",
                    "title": "MARA &amp; VELA - Fliegen davon (Official Video)",
                    "description": "Fliegen davon ▻▻ https://maraxvela.lnk.to/fliegendavon MARA Socials ▻▻ https://lnkfi.re/immernochmara VELA Socials ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/apJTVSnpeBw/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/apJTVSnpeBw/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/apJTVSnpeBw/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "MARA",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-05-10T13:55:54Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "Fz5_q9XAW_YwIvWwo4yQc9PCUfg",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "9_8N6IgyZWc"
                },
                "snippet": {
                    "publishedAt": "2023-07-12T10:03:32Z",
                    "channelId": "UCEHlEJkxeh0V7hbmE2zKX0Q",
                    "title": "Scars",
                    "description": "Provided to YouTube by DistroKid Scars · baby hayabusa · neophron Deadly Poison Sting ℗ Vorkuta Fugitive Released on: ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/9_8N6IgyZWc/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/9_8N6IgyZWc/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/9_8N6IgyZWc/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "baby hayabusa - Topic",
                    "liveBroadcastContent": "none",
                    "publishTime": "2023-07-12T10:03:32Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "LApZknNsLuOL5ePMpZVm316xWz0",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "LnVdMr2Qyv4"
                },
                "snippet": {
                    "publishedAt": "2024-09-03T22:00:26Z",
                    "channelId": "UCeg0PVLfsBKFLGd9V_EvbGA",
                    "title": "06. Emily blunt (Audio Officiel)",
                    "description": "06. JeanJass - Emily blunt ❄️ Tous ces ongles rongés disponible sur toutes les plateformes ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/LnVdMr2Qyv4/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/LnVdMr2Qyv4/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/LnVdMr2Qyv4/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "Caballero & JeanJass",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-09-03T22:00:26Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "cADM_0_5F2DwQoPBP7sY4pPDUSg",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "x1B4uSdlRJE"
                },
                "snippet": {
                    "publishedAt": "2023-07-27T10:04:16Z",
                    "channelId": "UCn62-x5p-B8on9oh1kIA4iQ",
                    "title": "MILLIONS",
                    "description": "Provided to YouTube by Play Two MILLIONS · ADÉS THE PLANET MILLIONS ℗ Low Wood Released on: 2023-07-28 Composer: ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/x1B4uSdlRJE/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/x1B4uSdlRJE/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/x1B4uSdlRJE/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "ADÉS THE PLANET - Topic",
                    "liveBroadcastContent": "none",
                    "publishTime": "2023-07-27T10:04:16Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "1fIqgdb5nDA9nrUx4X3fO1OEE_c",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "pfNxAxc0lR4"
                },
                "snippet": {
                    "publishedAt": "2024-07-21T10:02:48Z",
                    "channelId": "UCjnC9HCogCQvMrdU7_uUiBg",
                    "title": "Bah Ouais - Nouvelle École",
                    "description": "Provided to YouTube by IIP-DDS Bah Ouais - Nouvelle École · Dadi Nouvelle École | Saison 3 | La Finale (Série Netflix) ℗ Netflix ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/pfNxAxc0lR4/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/pfNxAxc0lR4/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/pfNxAxc0lR4/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "DADI - Topic",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-07-21T10:02:48Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "jzrp3lVTe8j88rElspM05fHUJaI",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "3zr25sUK3Eg"
                },
                "snippet": {
                    "publishedAt": "2024-11-14T10:02:01Z",
                    "channelId": "UCn62-x5p-B8on9oh1kIA4iQ",
                    "title": "JTM",
                    "description": "Provided to YouTube by Play Two JTM · ADÉS THE PLANET UN TRÈS BEL ENTERREMENT ℗ Low Wood Released on: ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/3zr25sUK3Eg/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/3zr25sUK3Eg/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/3zr25sUK3Eg/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "ADÉS THE PLANET - Topic",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-11-14T10:02:01Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "WJmV-zIlincXj3HXxMINMAZ2svk",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "wz96o2Ovfek"
                },
                "snippet": {
                    "publishedAt": "2022-10-19T10:01:45Z",
                    "channelId": "UChAzoxEXyBUJI6iVimc0RCg",
                    "title": "3 Meufs",
                    "description": "Provided to YouTube by Opale Système 3 Meufs · H JeuneCrack 3ème Cycle ℗ 2022 Opale Système Released on: 2022-10-20 ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/wz96o2Ovfek/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/wz96o2Ovfek/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/wz96o2Ovfek/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "H JeuneCrack - Topic",
                    "liveBroadcastContent": "none",
                    "publishTime": "2022-10-19T10:01:45Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "y6zIlNhHC7wNByLbIx50bQ_NGpQ",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "1F3OGIFnW1k"
                },
                "snippet": {
                    "publishedAt": "2024-11-23T08:01:00Z",
                    "channelId": "UCltUcam72sZccQxDxlqAllQ",
                    "title": "Stromae, Pomme - “Ma Meilleure Ennemie” (from Arcane Season 2) [Official Visualizer]",
                    "description": "Stream “Ma Meilleure Ennemie” from the Arcane Season 2 Original Soundtrack: https://arcane.lnk.to/mameilleureennemie Stream ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/1F3OGIFnW1k/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/1F3OGIFnW1k/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/1F3OGIFnW1k/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "Riot Games Music",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-11-23T08:01:00Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "d-eOvwiewUiiSRNiW1OLHotZLPY",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "WBoO2QjKLlI"
                },
                "snippet": {
                    "publishedAt": "2024-02-05T17:00:11Z",
                    "channelId": "UCDF0yJAHlOB8xlNNeAsQTIQ",
                    "title": "Sheng - DIS-MOI PK ?! 为什么 (Clip)",
                    "description": "SHENG - DIS-MOI PK ?! 为什么 (Clip) Ecouter LA PLAYLIST DE SHENG ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/WBoO2QjKLlI/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/WBoO2QjKLlI/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/WBoO2QjKLlI/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "sheng",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-02-05T17:00:11Z"
                }
            },
            {
                "kind": "youtube#searchResult",
                "etag": "USzDc3qeWuhbgwjN7yKSm6K45qc",
                "id": {
                    "kind": "youtube#video",
                    "videoId": "eDh64IBvQ1Q"
                },
                "snippet": {
                    "publishedAt": "2024-02-14T23:01:15Z",
                    "channelId": "UCQLW20NyN2i1-M34ysF0JcA",
                    "title": "Fast Learner",
                    "description": "Provided to YouTube by Monde Libre Fast Learner · Mairo · H JeuneCrack La solution ℗ 2024 Monde Libre / Opale Système ...",
                    "thumbnails": {
                        "default": {
                            "url": "https://i.ytimg.com/vi/eDh64IBvQ1Q/default.jpg",
                            "width": 120,
                            "height": 90
                        },
                        "medium": {
                            "url": "https://i.ytimg.com/vi/eDh64IBvQ1Q/mqdefault.jpg",
                            "width": 320,
                            "height": 180
                        },
                        "high": {
                            "url": "https://i.ytimg.com/vi/eDh64IBvQ1Q/hqdefault.jpg",
                            "width": 480,
                            "height": 360
                        }
                    },
                    "channelTitle": "Mairo - Topic",
                    "liveBroadcastContent": "none",
                    "publishTime": "2024-02-14T23:01:15Z"
                }
            }
        ]

        temp = [];

        for (let index = 0; index < tata.length; index++) {
            const element = tata[index];
            results[index].youtubeId = element.id.videoId;
            results[index].isPlaying = false;
        }
        return results;
    }

    async getTopTracks() {
        const token = this.getCookie('token'); // Assure-toi que getCookie est bien implémentée
        const refresh_token = this.getCookie('refreshToken');
        let topTracks = [];
        let results = [];
        let name = [];
        if (token) {
            try {
                // Requête pour obtenir les pistes
                const response = await axios.get('https://api.spotify.com/v1/me/top/tracks', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params: {
                        limit: 10,
                        time_range: 'short_term'
                    }
                });

                topTracks = response.data.items
                for (let index = 0; index < topTracks.length; index++) {
                    const element = topTracks[index];
                    name.push(element.name.replace('&', '-') + ' ' + element.artists[0].name.replace('&', '-'));
                }
                results = await axios.get(`${URL_BASE_API}/similarBySpotifyTracks?name=${name}&token=${token}`);
    
            } catch (error) {
                // Gestion des erreurs
                console.error('Erreur lors de la récupération des pistes :', error.response ? error.response.data : error.message);
            }
        } else if(!token && refresh_token) {
            await this.refreshAccessToken(refresh_token)
            return this.getTopTracks();
        } else {
            console.error("Le token est manquant ou invalide.");
        }
        return results.data.results;
    }

    async getYoutubeVideo(search) {
        try {
            const { data: { results = [] } = {} } = await axios.get(`${URL_BASE_API}/youtubeID`, { params: { search } });
            return results;
        } catch (error) {
            console.error(`Erreur lors de la requête vers MusicBrainz avec "${search}":`, error.message);
            return [];
        }
    }

    async getLovedTracks(offset = 0) {
        const token = this.getCookie('token'); // Assure-toi que getCookie est bien implémentée
        const refresh_token = this.getCookie('refreshToken');
        if(!token && refresh_token) {
            // console.log('refresh_token', refresh_token);
            await this.refreshAccessToken(refresh_token)
            return this.getLovedTracks();
        }
        try {
            const { data: { results = [] } = {} } = await axios.get(`${URL_BASE_API}/getLoved`, { params: {token, offset} });
            return results;
        } catch (error) {
            console.error(`Erreur lors de la requête vers MusicBrainz avec :`, error.message);
            return [];
        }
        
    }

    get_average_rgb(img) {
        const colorThief = new ColorThief();
        let color = [];
        // let background = "";
        img.crossOrigin = 'anonymous';

        if (img.complete) {
            let couleur = colorThief.getPalette(img, 3);
            for (let index = 0; index < couleur.length; index++) {
                const element = couleur[index];
                color.push({rgb: `rgb(${element[0]}, ${element[1]}, ${element[2]})`, code: element});
                
            }
            // console.log('couleursTriees', couleursTriees);
            // console.log('color', color);
            // background = `rgb(${color[0][0]}, ${color[0][1]}, ${color[0][2]})`;
            // for (let index = 0; index < color.length; index++) {
            //     const element = color[index];
            //     if (element[0] < 200 && element[1] < 200 && element[2] < 200) {
            //         background = `rgb(${element[0]}, ${element[1]}, ${element[2]})`;
            //         break;
            //     }
            // }
            
            // background = `rgb(${color[0] < 200 ? color[0] : "200"}, ${color[0] < 200 ? color[0] : "200"}, ${color[1] < 200 ? color[2] : "200"})`;
        }
        // color = colorThief.getColor(img);
        // console.log(colorThief.getPalette(img));

        // document.getElementsByClassName('degrader')[0].style = `background: linear-gradient(180deg, rgb(${color[0]}, ${color[1]}, ${color[2]}) 0%, rgb(${color[0]}, ${color[1]}, ${color[2]}) 50%, rgb(0, 0, 0) 100% );`;
        // document.querySelector('.img-shadow').style = `background: linear-gradient(0deg, rgb(${color[0]}, ${color[1]}, ${color[2]}) 0%, rgba(0,0,0,0) 10%);`;
        // console.log(color);
        return color;
    }

    getSuitableColor(colors, threshold = 50) {
        // Fonction pour calculer la luminosité d'une couleur
        function calculateBrightness(rgb) {
            return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]);
        }
    
        // Vérifie chaque couleur dans l'ordre de priorité
        for (let i = 0; i < colors.length; i++) {
            const brightness = calculateBrightness(colors[i].code);
            if (brightness >= threshold) {
                return colors[i];
            }
        }
    
        // Si aucune couleur n'est assez claire, retourne la plus lumineuse
        return colors.reduce((lightest, color) => {
            const brightness = calculateBrightness(color.code);
            return brightness > calculateBrightness(lightest.code) ? color : lightest;
        }, colors[0]);
    }

}

export default dooverAPI;