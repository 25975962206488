<template>
    <div class="checkbox-wrapper-4">
        <input class="inp-cbx" :id="cookieName" type="checkbox" :checked="checked" v-on:change="majChecked">
        <label class="cbx" :for="cookieName">
            <span>
                <svg width="12px" height="10px"></svg>
            </span>
            <span>{{ text }}</span>
        </label>
        <svg class="inline-svg">
            <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
        </svg>
    </div>
</template>

<script>
import DooverAPI from "../common/api";
export default {
    name: 'Checkbox-vue',
    props: {
        text: {
            type: String,
            default: '',
        },
        cookieName: {
            type: String,
            default: '',
        },
        default: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checked: null,
        };
    },
    mounted() {
        const dooverAPI = new DooverAPI();
        const cookieValue = dooverAPI.getCookie(this.cookieName);

        if (cookieValue === null) {
            dooverAPI.setCookie(this.cookieName, this.default.toString(), 9999999);
            this.checked = this.default;
        } else {
            this.checked = cookieValue === "true";
        }
    },
    methods: {
        majChecked() {
            this.checked = !this.checked;
            new DooverAPI().setCookie(this.cookieName, this.checked, 9999999);
        },
    },
};
</script>

<style scoped>
.checkbox-wrapper-4 * {
    box-sizing: border-box;
    color: white;
}

.checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 0px 1px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: inline-block;
}

.checkbox-wrapper-4 .cbx:not(:last-child) {
    margin-right: 6px;
}

.checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(242, 91, 34, 0.05);
}

.checkbox-wrapper-4 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:last-child {
    padding-left: 8px;
    line-height: 18px;
}

.checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: #f25b22;
}

.checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child {
    background: #f25b22;
    border-color: #f25b22;
    animation: wave-4 0.4s ease;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}

@media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
        width: 100%;
        display: inline-block;
    }
}

@-moz-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

@-webkit-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

@-o-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

@keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}
</style>
