<template>
  <div class="home">
    <h1>Home</h1>
    <button @click="loginWithSpotify">Se connecter avec Spotify</button>
    <p>
      <a href="/">Discover</a>
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

import DooverAPI from "../../src/common/api";
export default {
  name: 'HomeView',
  methods: {
    loginWithSpotify() {
      const dooverAPI = new DooverAPI();
      dooverAPI.setCookie('token', 'waiting', 10000);
      window.location.href = dooverAPI.getSpotifyAuthURL();
    },
  },
}
</script>
