<template>
    <vue-scroll-snap>
        <div class="item" v-for="(track, index) in tracks" :id="'item-' + index" :key="index" :data-index="index">
            <div class="top-spacer"></div>
            <div class="image" v-if="!track.youtubeId">
                <img :src="track.cover ? track.cover : require('../assets/simpleCover.png')" :alt="'Album cover for ' + track.title + ' by ' + track.artist" v-on:load="getDominantColor(index)" :id="'image-' + index" />
            </div>
            <div class="ytVideo" v-if="track.youtubeId">
                <YoutubePlayer :is-playing="track.isPlaying" :videoId="track.youtubeId" :index="index" :lightness="track.lightness" />
            </div>
                <!-- @playerStateChange="changePlayerState" -->
            <div class="bottom">
                <div class="data">
                    <div class="text marquee-container">
                        <h1 class="defilement" :id="'title-' + index"><ScrollingText :text="track.title" :playAnimation="track.playAnimation" /></h1>
                        <p class="artists"><ScrollingText :text="track.artist" :playAnimation="track.playAnimation" /></p>
                        <p><span class="material-symbols-outlined">album</span><ScrollingText :text="track.album" :playAnimation="track.playAnimation"/></p>
                    </div>
                </div>
                <div class="linksButton">
                    <div class="links">
                        <button v-on:click="toggleLike(index)" :class="track.liked ? 'liked' : ''" ><span class="material-symbols-rounded">favorite</span></button>
                        <a :href="'/'+track.id" class="searchBtn"><span class="material-symbols-rounded">search</span></a>
                        <!-- <a :href="'https://www.youtube.com/watch?v=' + track.youtubeId" target="_blank" class="youtubeBtn"><span class="material-symbols-outlined">youtube_activity</span></a> -->
                        <a v-if="track.spotify" :href="track.spotify.uri" target="_blank" class="spotifyBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 236.05 225.25">
                                <defs></defs>
                                <path class="cls-1" d="m122.37,3.31C61.99.91,11.1,47.91,8.71,108.29c-2.4,60.38,44.61,111.26,104.98,113.66,60.38,2.4,111.26-44.6,113.66-104.98C229.74,56.59,182.74,5.7,122.37,3.31Zm46.18,160.28c-1.36,2.4-4.01,3.6-6.59,3.24-.79-.11-1.58-.37-2.32-.79-14.46-8.23-30.22-13.59-46.84-15.93-16.62-2.34-33.25-1.53-49.42,2.4-3.51.85-7.04-1.3-7.89-4.81-.85-3.51,1.3-7.04,4.81-7.89,17.78-4.32,36.06-5.21,54.32-2.64,18.26,2.57,35.58,8.46,51.49,17.51,3.13,1.79,4.23,5.77,2.45,8.91Zm14.38-28.72c-2.23,4.12-7.39,5.66-11.51,3.43-16.92-9.15-35.24-15.16-54.45-17.86-19.21-2.7-38.47-1.97-57.26,2.16-1.02.22-2.03.26-3.01.12-3.41-.48-6.33-3.02-7.11-6.59-1.01-4.58,1.89-9.11,6.47-10.12,20.77-4.57,42.06-5.38,63.28-2.4,21.21,2.98,41.46,9.62,60.16,19.74,4.13,2.23,5.66,7.38,3.43,11.51Zm15.94-32.38c-2.1,4.04-6.47,6.13-10.73,5.53-1.15-.16-2.28-.52-3.37-1.08-19.7-10.25-40.92-17.02-63.07-20.13-22.15-3.11-44.42-2.45-66.18,1.97-5.66,1.15-11.17-2.51-12.32-8.16-1.15-5.66,2.51-11.17,8.16-12.32,24.1-4.89,48.74-5.62,73.25-2.18,24.51,3.44,47.99,10.94,69.81,22.29,5.12,2.66,7.11,8.97,4.45,14.09Z" />
                            </svg>
                        </a>
                    </div>
                    <div class="playPauseButtons" v-if="!track.youtubeId">
                        <button v-on:click="showYoutubeVideo(index)" class="youtubePlayButton"><span class="material-symbols-rounded">play_circle</span></button>
                    </div>
                    <div v-if="track.youtubeId" :class="track.isPlaying ? 'paused' : 'playing'" class="playPauseButtons">
                        <button class="play" v-on:click="changePlayerState(index)"><span class="material-symbols-rounded">play_arrow</span></button>
                        <button class="pause" v-on:click="changePlayerState(index)"><span class="material-symbols-rounded">pause</span></button>
                    </div>
                </div>
            </div>
            <Observer v-if="index == tracks.length - 2" @intersect="intersected" />
            <div class="songEmpty" v-if="loading && index == tracks.length - 1">
                <Loader />
            </div>
        </div>
        <div class="item" v-if="showLoadMore">
            <div class="loadMore">
                <LoadMoreBtn v-on:click="loadMore" />
            </div>
        </div>
        <!-- <div class="item" v-if="loading">
            <div class="songEmpty">
                <Loader />
            </div>
        </div> -->
    </vue-scroll-snap>
    <div class="songEmpty" v-if="loading && tracks.length == 0">
        <Loader />
    </div>
    <div class="songEmpty" v-if="tracks.length == 0 && !loading">
        <h1 v-if="!loading">There is no song to display, you can search a song and we will display some recommandation</h1>
    </div>
</template>

<script>
import VueScrollSnap from "vue-scroll-snap";
import Observer from "../components/Observer.vue";
import DooverAPI from "../common/api";
import YoutubePlayer from "./YoutubePlayer.vue";
import ScrollingText from './ScrollingText.vue';
import Loader from './Loader.vue';
import LoadMoreBtn from "./LoadMoreBtn.vue";

export default {
    name: "Discover-view",
    components: {
        VueScrollSnap,
        Observer,
        YoutubePlayer,
        ScrollingText,
        Loader,
        LoadMoreBtn,
    },
    data() {
        return {
            tracks: [],
            newTracks: [],
            songObserver: null,
            loading: false,
            showLoadMore: false,
        };
    },
    mounted() {
        this.getTrack();
    },
    methods: {
        async getTrack() {
            const dooverAPI = new DooverAPI();
            this.loading = true;
            if (this.$route.params.id) {
                await dooverAPI.getSimilarTracks(this.$route.params.id).then((res) => {
                    this.tracks =  res;
                });
            } else {
                this.tracks = await dooverAPI.getTopTracks();
            }
            this.loading = false;
            if (this.tracks.length > 0) {
                setTimeout(() => {
                    this.observeItems(); // Démarrer l'observation après avoir chargé les pistes
                }, 100);
            }
        },
        getDominantColor(value) {
            const dooverAPI = new DooverAPI();
            let image = document.getElementById('image-' + value);
            const color = dooverAPI.get_average_rgb(image);
            if (color.length > 0) {
                this.tracks[value].color = color;
                let lightness = dooverAPI.getSuitableColor(color).rgb;
                this.tracks[value].lightness = lightness;
                document.getElementById('item-' + value).style.backgroundColor = lightness;
                document.getElementById('title-' + value).style.color = lightness;
                document.getElementById('image-' + value).style.border = `solid 15px ${lightness}`;
            }
        },

        observeItems() {
            const options = {
                root: null, // Observer par rapport au viewport
                threshold: 0.75, // Activer quand 75% de l'élément est visible
            };

            // Déconnecter l'ancien observer s'il existe
            if (this.songObserver) {
                this.songObserver.disconnect();
            }

            // Créer un nouvel IntersectionObserver
            this.songObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const index = entry.target.getAttribute('data-index');
                    
                    if (entry.isIntersecting) {
                        this.tracks[index].isPlaying = false;
                        this.tracks[index].playAnimation = true;

                    } else {
                        this.tracks[index].isPlaying = false;
                        this.tracks[index].playAnimation = false;
                    }
                });
            }, options);

            // Observer chaque élément de piste
            this.tracks.forEach((_, index) => {
                const item = document.getElementById(`item-${index}`);

                // Vérifie si l'élément à observer existe
                if (item) {
                    item.setAttribute('data-index', index); // Ajoute un attribut d'index unique
                    this.songObserver.observe(item); // Observer l'élément
                }
            });
        },
        async intersected() {
            let infiniteScroll = new DooverAPI().getCookie('infiniteScroll');
            if (infiniteScroll === 'false') {
                this.showLoadMore = true;
                return;
            }
            await this.loadMore();
        },
        async loadMore() {
            this.showLoadMore = false;
            this.loading = true;
            if (this.tracks.length > 0) {
                await this.getMoreTracks().then(() => {
                    this.loading = false;
                });
            }
        },
        async getMoreTracks() {
            const dooverAPI = new DooverAPI();
            let ids = [];
            this.tracks = this.tracks || [];
            this.newTracks = this.newTracks || [];

            let sourceTracks = this.newTracks.length > 0 ? this.newTracks : this.tracks;
            if (sourceTracks.length > 0) {
                if (sourceTracks.length <= 5) {
                    ids = sourceTracks.map((track) => track.id);
                } else {
                    while (ids.length < 5 && ids.length < sourceTracks.length) {
                        const randomIndex = Math.floor(Math.random() * sourceTracks.length);
                        const element = sourceTracks[randomIndex];
                        if (!ids.includes(element.id)) {
                            ids.push(element.id);
                        }
                    }
                }
            }
            try {
                this.newTracks = [];
                const newSimilar = await dooverAPI.getSimilarTracks(ids);
                newSimilar.forEach((element) => {
                    if (!this.tracks.some((track) => track.id === element.id)) {
                        this.newTracks.push(element);
                    }
                });
            } catch (error) {
                console.error("Erreur lors de la récupération de pistes similaires :", error);
                return; // Arrête l'exécution en cas d'erreur
            }
            this.tracks = [...this.tracks, ...this.newTracks];
        },
        changePlayerState(index) {
            this.tracks[index].isPlaying = !this.tracks[index].isPlaying;
        },
        async showYoutubeVideo(index) {
            const dooverAPI = new DooverAPI();
            this.tracks[index].youtubeId = await dooverAPI.getYoutubeVideo(this.tracks[index].title + ' ' + this.tracks[index].artist);

        },
        async toggleLike(index) {
            const dooverAPI = new DooverAPI();
            this.tracks[index].liked = await dooverAPI.toggleLike(this.tracks[index]);
        }
    }
};
</script>

<style lang="scss" scoped>
    .cls-1 {
        fill: #0bb06c;
        stroke-width: 0px;
    }
    .scroll-snap-container {
        color: white;
        height: 100vh;
        position: relative;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        .item {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            .top-spacer {
                display: block;
                height: 7vh;
            }
            .image {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                height: calc(40% - 7vh);
                position: relative;
                -webkit-box-shadow: inset 0px -25px 0px 0px #000000; 
                box-shadow: inset 0px -25px 0px 0px #000000;
                img {
                    // width: 80%;
                    height: 95%;
                    aspect-ratio: 1 / 1;
                    border-radius: 5px;
                }
            }
            .ytVideo {
                height: calc(40% - 7vh);
                display: flex;
                align-items: flex-end;
                justify-content: center;
                -webkit-box-shadow: inset 0px -25px 0px 0px #000000; 
                box-shadow: inset 0px -25px 0px 0px #000000;
            }
            .bottom {
                height: 60%;
                background-color: black;
                width: 100%;
                position: relative;
                .data {
                    font-family: "Audiowide", serif;
                    .text {
                        .defilement {
                            // overflow: hidden;
                            white-space: nowrap;
                            animation: defilement 5s linear infinite;
                        }
                        h1 {
                            font-size: 50px;
                            font-weight: 700;
                            margin: 15px 0px 15px 15px;
                            height: 60px;
                        }
                        p {
                            color: #A4A6A5;
                            font-size: 20px;
                            margin: 0 0 15px 15px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            span {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .linksButton {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 20px;
                    
                    .links {
                        width: 50%;
                        display: flex;
                        flex-wrap: wrap;
                        a, button {
                            background: none;
                            text-decoration: none;
                            border: none;
                            outline: none;
                            color: white;
                            background-color: #3A3A3A;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 10px 0px 10px 25px;
                            .material-symbols-outlined {
                                font-size: 30px;
                            }
                            &.liked {
                                color: #f25b22;
                                .material-symbols-rounded {
                                    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
                                }
                            }
                            svg {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                    .playPauseButtons {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.playing {
                            .play {display: block;}
                            .pause {display: none;}
                        }
                        &.paused {
                            .play {display: none;}
                            .pause {display: block;}
                        }
                        button {
                            background: none;
                            border: none;
                            outline: none;
                            color: black;
                            background-color: #A4A6A5;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &.youtubePlayButton {
                                .material-symbols-rounded {
                                    font-size: 60px;
                                    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24
                                }
                            }
                            .material-symbols-rounded {
                                font-size: 50px;
                                font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24
                            }
                            
                        }
                    }
                }

            }
            .loadMore {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
    }
    .songEmpty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        position: absolute;
        bottom: 0px;
        background-color: black;
        .loading {
            color: #f25b22;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            span {
                font-size: 50px;
                font-weight: 700;
                animation: laoding 3s linear infinite;
            }

            @keyframes laoding {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
        h1 {
            color: white;
            font-size: 30px;
            font-weight: 700;
            text-align: center;
        }
        #text {
            display: inline-block;
            opacity: 0;
            animation: fadeInOut 3s infinite;
            margin-bottom: 50px;
            height: 90px;
            text-align: center;
        }
        @keyframes fadeInOut {
            0% {
                opacity: 0;
            }
            10%,
            40% {
                opacity: 1;
            }
            50%,
            100% {
                opacity: 0;
            }
        }
    }
</style>
