<template>
    <div class="button-container">
        <button class="button" :class="{ active: page === 'loved' }">
            <a href="/loved"><span class="material-symbols-rounded">bookmark_heart</span></a>
        </button>
        <button class="button" :class="{ active: page === 'discover' }">
            <a href="/"><span class="material-symbols-rounded">home</span></a>
        </button>
        <!-- <button class="button">
            <a href=""><span class="material-symbols-rounded">send</span></a>
        </button> -->
    </div>
</template>

<script>

export default {
    name: "Home-nav",
    props: ["page"],
    data() {
        return {};
    },

};
</script>

<style scoped>
.button-container {
    display: flex;
    background-color: rgb(242, 91, 34);
    height: 40px;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px,
        rgba(242, 91, 34, 0.5) 0px 7px 15px;
    position: fixed;
    bottom: 20px;
    width: 90%;
    z-index: 100;
    left: 5%;

    @media screen and (min-width: 700px) {
        display: none;
    }
    
    .button {
        outline: 0;
        border: 0;
        width: 32%;
        height: 90%;
        border-radius: 10px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: all ease-in-out 0.3s;
        cursor: pointer;

        &.active {
            background-color: rgba(0, 0, 0, 0.2);
        }   

        a {
            color: #fff;
        }
    }
}

</style>
