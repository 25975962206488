<template>
    <div class="loader">
        <div class="card">
            <div class="loader-words">
                <p>Analyzing</p>
                <div class="words">
                    <span class="word"></span>
                    <span class="word">artists</span>
                    <span class="word">feats</span>
                    <span class="word">tempo</span>
                    <span class="word">songs</span>
                </div>
            </div>
        </div>
            <div class="loader-circle">
                <div class="circle">
                    <div class="dot"></div>
                    <div class="outline"></div>
                </div>
                <div class="circle">
                    <div class="dot"></div>
                    <div class="outline"></div>
                </div>
                <div class="circle">
                    <div class="dot"></div>
                    <div class="outline"></div>
                </div>
                <div class="circle">
                    <div class="dot"></div>
                    <div class="outline"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Loader-suggestions",
    data() {
        return { };
    },

    };
</script>

<style scoped>
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}
.loader-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    --color: #f25b22;
    --animation: 2s ease-in-out infinite;
}

.loader-circle .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    border: solid 2px var(--color);
    border-radius: 50%;
    margin: 0 10px;
    background-color: transparent;
    animation: circle-keys var(--animation);
}

.loader-circle .circle .dot {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--color);
    animation: dot-keys var(--animation);
}

.loader-circle .circle .outline {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: outline-keys var(--animation);
}

.circle:nth-child(2) {
    animation-delay: 0.3s;
}

.circle:nth-child(3) {
    animation-delay: 0.6s;
}

.circle:nth-child(4) {
    animation-delay: 0.9s;
}

.circle:nth-child(5) {
    animation-delay: 1.2s;
}

.circle:nth-child(2) .dot {
    animation-delay: 0.3s;
}

.circle:nth-child(3) .dot {
    animation-delay: 0.6s;
}

.circle:nth-child(4) .dot {
    animation-delay: 0.9s;
}

.circle:nth-child(5) .dot {
    animation-delay: 1.2s;
}

.circle:nth-child(1) .outline {
    animation-delay: 0.9s;
}

.circle:nth-child(2) .outline {
    animation-delay: 1.2s;
}

.circle:nth-child(3) .outline {
    animation-delay: 1.5s;
}

.circle:nth-child(4) .outline {
    animation-delay: 1.8s;
}

.circle:nth-child(5) .outline {
    animation-delay: 2.1s;
}

@keyframes circle-keys {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes dot-keys {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes outline-keys {
    0% {
        transform: scale(0);
        outline: solid 20px var(--color);
        outline-offset: 0;
        opacity: 1;
    }

    100% {
        transform: scale(1);
        outline: solid 0 transparent;
        outline-offset: 20px;
        opacity: 0;
    }
}
.card {
    /* color used to softly clip top and bottom of the .words container */
    padding: 1rem 2rem;
}
.loader-words {
    color: rgb(124, 124, 124);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    padding: 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
}

.words {
    overflow: hidden;
    position: relative;
}
.words::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 20;
}

.word {
    display: block;
    height: 100%;
    padding-left: 6px;
    color: #f25b22;
    animation: spin_4991 6s infinite;
}

@keyframes spin_4991 {
    10% {
        -webkit-transform: translateY(-102%);
        transform: translateY(-102%);
    }

    25% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    35% {
        -webkit-transform: translateY(-202%);
        transform: translateY(-202%);
    }

    50% {
        -webkit-transform: translateY(-200%);
        transform: translateY(-200%);
    }

    60% {
        -webkit-transform: translateY(-302%);
        transform: translateY(-302%);
    }

    75% {
        -webkit-transform: translateY(-300%);
        transform: translateY(-300%);
    }

    85% {
        -webkit-transform: translateY(-402%);
        transform: translateY(-402%);
    }

    100% {
        -webkit-transform: translateY(-400%);
        transform: translateY(-400%);
    }
}
</style>
