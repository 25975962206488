<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="searchCard">
    <searchCard/>
  </div>
  <router-view/>
</template>

<script>
import SearchCard from "@/components/SearchCard.vue";

export default {
    name: "App-view",
    components: {
      SearchCard,
    },
    data() {},
};
</script>

<style lang="scss">
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Roboto", serif;
  }
  body {
    background-color: black;
  }
  @media screen and (min-width: 700px) {
    #app {
      display: flex;
    }
    .searchCard {
      width: 40%;
      max-width: 500px;
      position: relative;
      height: 100vh;
    }
  }
</style>
